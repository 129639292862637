/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  height: 0;
  font-size: 40px;
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 40px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  color: #FFF;
  text-decoration: none;
  height: 40px;
  box-sizing: border-box;

  border-radius: 50%;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  position: absolute;
  top: 15px;
  width: 50%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
  margin: 5px;
  background: @color-white;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border: 4px solid @color-red;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: @color-red;
}


.owl-carousel.owl-drag .owl-item {
  max-height: 400px;
}

.owl-nav button {
  height: 45px;
  line-height: 40px;
  display: flex;
  align-items: center;
}

.owl-nav span {
  font-size: 40px;
}


#nav-left, #nav-right {
  fill: @color-white;
  &:hover {
    fill: @color-red;
  }
}


.owl-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 20px;
  color: #fff !important;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.49);
  & * {
    color: #fff !important;
  }
}