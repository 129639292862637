.cal-wrapper {
    display: block;
    width: 215px;
    border: 1px solid #000;
    border-radius: 4px;
    margin: 20px 0 20px auto;
}
.cal-nav {
    display: block;
    height: 25px;
    margin: 0 0 6px 0;
    overflow: hidden;
    border-bottom: 1px solid #6c6c6c;
    padding: 5px 0 2px;
}
.cal-nav-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: #000;
    font-weight: bold;
}
.cal-now-month-title {
    display: inline-block;
    width: 155px;
    height: 25px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 14px;
}
.cal-item {
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    color: #6c6c6c;
    margin: 2px;
    line-height: 26px;
    font-size: 14px;
}
.cal-now-month {
    color: #000000;
    cursor: pointer;
}
.cal-link {
    text-decoration: none;
    color: #000;
    text-align: center;
    font-size: 14px;
    /*background: #ffd59d;*/
    border-radius: 50%;
    display: block;
    width: 26px;
    height: 26px;
}
.cal-link.active {
    background: #9c9395;
}

.calendar-container {
    float: right;
}

.news-calendar {
    width: auto;
    float: none;
}

.news-calendar .btn {
    margin-top: 5px;
    display: inline-block;
}

.time-list-item {
    border-bottom: 1px solid #d2d6de;
    padding: 10px 0;
    color: #d2d6de;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.time-list-item .time {
    margin-right: 10px;
}
.time-list-open {
    color: #000;
}

#fromAddEventContainer {
    display: none;
}

.now-day {
    border: 1px solid #000;
}

.add-time-event {
    margin-right: 10px;
}