
@font-face {
  font-family: 'Myriad Pro';
  src: url('./fonts/MyriadPro-Bold.eot');
  src: local('Myriad Pro Bold'), local('MyriadPro-Bold'),
  url('./fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MyriadPro-Bold.woff') format('woff'),
  url('./fonts/MyriadPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./fonts/MyriadPro-Regular.eot');
  src: local('Myriad Pro Regular'), local('MyriadPro-Regular'),
  url('./fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MyriadPro-Regular.woff') format('woff'),
  url('./fonts/MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('./fonts/MyriadPro-Light.eot');
  src: local('Myriad Pro Light'), local('MyriadPro-Light'),
  url('./fonts/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
  url('./fonts/MyriadPro-Light.woff') format('woff'),
  url('./fonts/MyriadPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

