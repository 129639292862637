@color-blue: #f7f4f1;
@color-dark-blue: #144259;
@color-light-blue: #F4F9FD;
//@color-green: #66BB6A;
@color-green: #125E84;
@color-grey-outline: #CECECE;
@color-light-grey: #F7F7F7;
@color-red: #A12D29;
@color-red-light: #a16b67;
@color-red-active: #c6382c;
@color-link: /*#007bff;*/ #86577b;
@color-border: #ddd;

@color-white: #ffffff;
@box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
@base-padding: 1.25rem;
@base-radius: 4px;

@color-primary-text: /*#007bff;*/ #815076;
@color-secondary-text: #455c71;
@color-primary: #455c71;
@color-secondary: #68375c;
@color-secondary-light: #fde6f6;
@color-secondary-border: #805d73;
@color-back: #f5f5f5;
@color-placeholder: #eaeaea;


/* background: rgb(69,92,113);
background: linear-gradient(50deg, rgba(69,92,113,1) 0%, rgba(151,117,155,1) 100%);*/


@font-base: Rubik, sans-serif;

@import "./_article.less";
@import "./_navButton.less";
@import "./_fonts.less";
@import "./_icon.less";
@import "./_input.less";
@import "./owl.carousel.min.css";
@import "owl.theme.default.less";
@import "./_special.less";
@import "simple-scrollbar.css";
@import "timetable.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";

img {
  max-width: 100%;
}

input {
}

::-webkit-input-placeholder { /* Chrome */
  color: @color-placeholder;
  transition: opacity 250ms ease-in-out;
}

:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

:-ms-input-placeholder { /* IE 10+ */
  color: @color-placeholder;
  transition: opacity 250ms ease-in-out;
}

:focus:-ms-input-placeholder {
  opacity: 0.5;
}

::-moz-placeholder { /* Firefox 19+ */
  color: @color-placeholder;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus::-moz-placeholder {
  opacity: 0.5;
}

:-moz-placeholder { /* Firefox 4 - 18 */
  color: @color-placeholder;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus:-moz-placeholder {
  opacity: 0.5;
}

a, a:focus, a:hover {
  color: @color-link;
  box-shadow: none;
}

.btn, .btn:focus, .btn:hover {
  box-shadow: none;
}

.ext-cont {
  & p {
    word-break: break-word;
    margin-bottom: 1rem !important;
  }

  & h1 {
    color: @color-primary-text;
  }

  & h2 {
    font-size: 2rem !important;
    margin-bottom: 24px !important;
    color: @color-primary-text;
  }
}

.color-red {
  color: @color-red !important;
}

.color-blue {
  color: @color-link !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.nowrap {
  white-space: nowrap;
}

/*@import "./perfect-scrollbar.css";*/

:root {
  --blue: @color-blue;
}

* {
  font-family: 'Myriad Pro', sans-serif;
  font-size: .95rem;
  font-weight: normal;
}

html,
body {
  height: 100%;
  font-size: 16px;
  line-height: 1.2rem;
}

body {
  background: @color-back;
}

.hidden {
  display: none;
}

select.form-control.is-invalid {
  background-position: right 1.2rem center;
}

.wrap {
  min-height: 100%;
  height: auto;
  margin: 0 auto -184px;
  padding: 0 0 184px !important;
  box-sizing: border-box;
  /*background: @color-light-grey;*/
  @media (max-width: 500px) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.wrap > .container, .wrap > .line-grey > .container {
  padding: 5px 15px;
}

.header-back {
  background: rgb(69, 92, 113);
  background: linear-gradient(50deg, rgba(69, 92, 113, 1) 0%, rgba(151, 117, 155, 1) 100%);
  width: 100%;
  height: 140px;
  position: absolute;
  z-index: -1;
}

.font-large .header-back {
  height: 160px;
}

.font-e-large .header-back {
  height: 220px;
}

.index .header-back {
  background: rgb(69, 92, 113);
  background: linear-gradient(50deg, rgba(69, 92, 113, 1) 0%, rgba(151, 117, 155, 1) 100%);
  width: 100%;
  height: 500px;
  position: absolute;
  z-index: -1;
}

.font-e-large .index .header-back {
  height: 900px;
}

.main-back {
  background: #e6e6e6;
  height: 650px;
  z-index: -2;
  position: absolute;
  margin-left: -15px !important;
}

.footer {
  height: 60px;
  background-color: @color-blue;
  border-top: 1px solid #ddd;
  padding-top: @base-padding;
}

.jumbotron {
  text-align: center;
  background-color: transparent;
}

.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

.not-set {
  color: #c55;
  font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  padding-left: 5px;
}

a.asc:after {
  content: /*"\e113"*/ "\e151";
}

a.desc:after {
  content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
  content: "\e153";
}

.sort-numerical a.desc:after {
  content: "\e154";
}

.sort-ordinal a.asc:after {
  content: "\e155";
}

.sort-ordinal a.desc:after {
  content: "\e156";
}

.grid-view th {
  white-space: nowrap;
}

.hint-block {
  display: block;
  margin-top: 5px;
  color: #999;
}

.error-summary {
  color: #a94442;
  background: #fdf7f7;
  border-left: 3px solid #eed3d7;
  padding: 10px @base-padding;
  margin: 0 0 15px 0;
}

#notice {
  z-index: 20;
  text-align: center;
}


header {
  padding-top: 20px;
}

footer {
  padding: 0;
  line-height: 36px;
  font-size: 18px;
  min-height: 150px;
  box-sizing: border-box;
  margin-top: 20px;
  background: rgb(69, 92, 113);
  background: linear-gradient(50deg, rgba(151, 117, 155, 1) 0%, rgba(69, 92, 113, 1) 100%);
  color: @color-white
}

.copyright {
  font-size: .8rem;
  line-height: 1.1rem;

  & a {
    color: @color-white;
  }
}

.footer-link-line {
  min-height: 120px;

  & a {
    display: inline-block;
    flex-direction: column;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.1rem;
    color: #000;
    margin-bottom: 10px;
    text-align: center;

    & span {
      display: block;
      height: 65px;
      margin-bottom: 10px;
    }
  }
}

.footer-bottom {
  min-height: 166px;
}

.footer-menu {
  display: flex;
  margin: 0 auto 30px;
  padding: 40px 0 0;
  text-align: left;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
}

.footer-menu li {
  display: flex;
  margin: 0;
  padding: 0 15px;
  color: @color-white;
  border-right: 1px solid @color-white;
  flex-grow: 1;
  justify-content: center;

  & a {
    font-size: 1rem;
  }

  &:last-child {
    padding-right: 0;
    border-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }

  @media (max-width: 1000px) {
    justify-content: flex-start;
    flex-grow: 0;
    padding-left: 0;
    border: 0;
    width: 25%;
  }
  @media (max-width: 800px) {
    width: 33.3%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}

.footer-menu a {
  color: @color-white;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.1rem;
}

.line-grey {
  background-color: @color-light-grey;
  overflow: hidden;
}

.carousel-indicators li {
  width: 16px;
  height: 16px;
  border-radius: 50%;

  &.active {
    background-color: @color-blue;
  }
}


.main-search {
  padding: @base-padding;

  &-block {
    margin-bottom: 15px;
  }

  &-title {
    text-align: center;
    color: @color-secondary-text;
    font-size: 2.6rem;
    font-weight: normal;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 40px;

    &:after {
      content: "";
      position: absolute;
      background: @color-secondary;
      width: 100px;
      height: 4px;
      margin: 0 auto;
      bottom: 0;
      left: calc(50% - 50px);
    }

  }

  .custom-checkbox {
    margin-bottom: 10px;
    line-height: 24px;
  }
}

.link-main-archive {
  font-size: 1.6rem;
  margin: 20px auto 30px;
  display: block;
}


input.is-valid:checked ~ .custom-control-label::before {
  border-color: @color-red !important;
  background-color: @color-red !important;
}

/*.custom-control-input.is-valid ~ .custom-control-label {
  color: @color-red;
}*/
.main-search #main-search-input {
  margin-right: 10px;
}

.main-search #main-search-button {
  width: 120px;
}


.navbar {
  padding: 1rem 0;
}


.unit-view-bar {
  margin: 0 auto;
}

.main-news-block {
  /*overflow: visible;*/
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow: hidden;
  padding-bottom: @base-padding;
  -webkit-overflow-scrolling: touch;
}

.part-block {
  box-sizing: border-box;
  border-radius: @base-radius;
  height: 100%;

  &-small {
    display: flex;
    height: 170px;
  }
}

.part-line {
  display: flex;
  justify-content: space-between;
  padding: @base-padding;

  &-small {
    padding: 0;
  }
}

.part-title, .part-date, .part-title a {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000;
}

.part-date {
  font-size: .9rem;
  font-weight: bold;
}

.news-img {
  height: 297px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /*background-color: @color-white;
  box-shadow: @box-shadow;*/

  &-small {
    height: 150px;
    width: 150px;
    flex-shrink: 0;
    margin-right: 20px;
  }
}

.news-container {
  padding: @base-padding;
  height: 250px;
  overflow: hidden;
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &-small {
    height: 100px;
    font-size: 1.25rem;
    line-height: 1.45rem;
    padding: 0;
    overflow: hidden;
    position: relative;
    display: block;
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    background-image: linear-gradient(rgba(247, 247, 247, 0) 0%, rgb(247, 247, 247) 80%);
  }
}

.news-title {
  font-size: 1.45rem;
  line-height: 1.7rem;
  font-weight: bold;
  margin-bottom: 10px;

  &-small {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.news-content {
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;
  overflow: hidden;
}

.institution-block {
  min-height: 1.5rem;
  text-align: right;
  padding: @base-padding;

  &-small {
    & a {
      color: #cdd0d7;
      font-size: 0.8rem;
      line-height: 1.45rem;
    }
  }

  & a {
    color: #CDD0D7;
    font-size: 1rem;
    line-height: 20px;
  }
}

#scroll-container {

}

.search-form {
  border: 1px solid @color-link;
  padding: 15px;
  margin: 0 0 @base-padding;
  border-radius: @base-radius;

  & label {
    color: #000;
  }

  & .btn-link, .btn-link:hover {
    color: @color-link;
  }
}

th {
  background: @color-blue;
  color: @color-link !important;
  border: 0 !important;

  & a {
    color: @color-link;

    &:hover {
      color: @color-link;
    }
  }
}

th.num {
  width: 110px;
}

th.dates {
  width: 180px;
}

.summary {
  margin-bottom: @base-padding;
}

.custom-control-label {
  line-height: 24px;
}

.content-breadcrumbs {
  font-size: 14px;
  margin-bottom: @base-padding;
}

/*color replace*/

.invalid-feedback {
  display: block;
}

@media (min-width: 576px) {
  .form-inline .form-control {
    max-width: 100%;
  }
}

tr {
  transition: all .5s ease-in-out;
}

.alert-primary {
  color: @color-link;
  background-color: @color-secondary-light;
  border-color: @color-secondary-border;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, .btn-primary:focus {
  background-color: @color-primary;
  border-color: @color-primary;
}

.btn-link {
  color: @color-link;
}

.btn-outline-success {
  border-color: @color-blue;
  color: @color-blue;

  &:hover {
    border-color: @color-blue;
    background-color: @color-blue;
    color: @color-white;
  }
}

.breadcrumb, .rounded {
  border-radius: 0 !important;
}

.form-control, .btn {
  border-radius: @base-radius !important;
}

.bg-blue {
  background-color: @color-blue;
}

header .container .navbar {
  align-items: center;
  @media (max-width: 780px) {
    height: 100px;
  }
}

.header-title {
  font-size: 4rem;
  line-height: 100%;
  color: @color-white;
  min-height: 150px;
  @media (max-width: 992px) {
    font-size: 1.7rem;
    line-height: 1.9rem;
    height: 70px;
  }
  @media (max-width: 780px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
    white-space: normal;
    height: 50px;
  }
}

.progress-value {
  color: #fff;
  padding-left: 10px;
  padding-top: 10px;
  position: absolute;
}

.header-info {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }

  & > div {
    margin: 10px 0;
    width: 32%;
    @media (max-width: 780px) {
      width: 100%;
    }
  }

  .info-block {
    padding: 25px;
    background-color: @color-white;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 992px) {
      padding: 10px;
    }
  }

  .info-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 145px;
    width: 145px;
    @media (max-width: 992px) {
      height: 100px;
      width: 100px;
    }
    margin: 0 auto;
  }

  .info-block_1 {
    background-image: url("../img/block-1.jpg");
  }

  .info-block_2 {
    background-image: url("../img/block-2.jpg");
  }

  .info-block_3 {
    background-image: url("../img/block-3.jpg");
  }

  .info-title {
    font-size: 2.15rem;
    line-height: 2.45rem;
    text-align: center;
    color: @color-primary-text;
    margin-bottom: 10px;
    font-weight: bold;
    @media (max-width: 992px) {
      font-size: 1.7rem;
      line-height: 1.9rem;
    }
    @media (max-width: 780px) {
      font-size: 1.2rem;
      line-height: 1.4rem;
      white-space: normal;
    }
  }


  .info-desc {
    min-height: 8rem;
    color: @color-secondary-text;
    font-size: 1.5rem;
    line-height: 1.75rem;

    & a.info-link {
      color: @color-secondary-text;
      font-size: 1.5rem;
      line-height: 1.75rem;
      @media (max-width: 992px) {

        font-size: 1.2rem;
        line-height: 1.4rem;
      }
      @media (max-width: 780px) {
        font-size: 1rem;
        line-height: 1.2rem;
        white-space: normal;
      }
    }

    @media (max-width: 992px) {

      font-size: 1.2rem;
      line-height: 1.4rem;
    }
    @media (max-width: 780px) {
      font-size: 1rem;
      line-height: 1.2rem;
      white-space: normal;
    }
  }

  .info-block-btn {
    display: flex;
    flex-direction: column;
  }

  .info-block-btn .btn {
    margin-bottom: 14px;
    font-size: .85rem;
  }
}

.brand {
  font-size: 25px;
  line-height: 28px;
  font-weight: 300;
  height: 100px;
  margin: 10px 0 0;
  @media (max-width: 992px) {
    height: 70px;
    padding: 20px 0 0 50px;
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: 780px) {
    height: 60px;
    background: none;
    padding: 0;
    margin: 0;
    width: 150px;
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    white-space: normal;
  }
}

caption {
  caption-side: top;
}

.accord-one {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  border-radius: calc(.25rem - 1px) !important;
  border-top-left-radius: calc(.25rem - 1px) !important;
  border-top-right-radius: calc(.25rem - 1px) !important;
}

.carousel-item {
  text-align: center;
  max-height: 400px;
}

.pagination li:first-child > a, .pagination li:first-child > span {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.pagination li:last-child > a, .pagination li:last-child > span {
  margin-right: 0;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: @color-link;
  background-color: @color-white;
  border: 1px solid #dee2e6;
}

.pagination > li.active a.page-link {
  z-index: 1;
  color: @color-white;
  background-color: @color-link;
  border-color: @color-link;
}

.progress {
  height: 1.2rem;
}

.progress-value {
  color: @color-white;
  padding-left: 10px;
  position: absolute;
}

.card-header {
  padding: 0;
  background: @color-light-blue;
  cursor: pointer;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1350px) {
  .container {
    max-width: 1280px;
  }
}

.modal .close span {
  font-size: 50px;
  line-height: 20px;
}

.toast {
  min-width: 300px;
}

label {
  line-height: 24px;
}

.btn-white {
  border: 1px solid @color-white;
  background: transparent;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.btn-white-blue {
  border: 1px solid @color-blue;
  background: transparent;
  color: #000;

  &:hover {
    color: @color-blue;
  }
}

.btn-green {
  border: 1px solid @color-primary;
  background: @color-primary;
  color: @color-white;

  &:hover {
    color: @color-white;
  }
}

.btn-danger {
  background: @color-red;
}


.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: @color-red-light;
  border-color: @color-red-light;
}

.btn-primary, .custom-control-input:checked ~ .custom-control-label::before {
  background-color: @color-primary;
  border-color: @color-primary;
}

.btn-primary:hover {
  background-color: @color-secondary;
  border-color: @color-secondary;
}

.green.custom-control-input:checked ~ .green.custom-control-label:before {
  background-color: @color-green !important;
  border-color: @color-white !important;
}

.white.custom-control-input:checked ~ .white.custom-control-label:before {
  background-color: @color-blue !important;
  border-color: @color-white !important;
  color: @color-blue !important;
}

.nav-bold {
  & a {
    color: @color-secondary-text !important;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.nav-pills .nav-link {
  color: #000;
  padding: .5rem 0;
  margin-right: 1rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: transparent;
  border-bottom: 2px solid @color-red;
  border-radius: 0;
  color: #000;
}

.nav-pills {
  border-bottom: 1px solid @color-grey-outline;
}

.badge-red {
  background: @color-primary;
  color: @color-blue;
  border-left: 1px solid @color-blue;
  border-right: 1px solid @color-blue;

}

.cont-shadow {
  box-shadow: @box-shadow;
}

/*archive info*/
.archive-block {
  border-top: 1px solid @color-border;
  display: flex;
  margin-bottom: @base-padding;
  align-items: stretch;
}

.archive-img {
  min-width: 230px;
  min-height: 170px;
  border-radius: @base-radius 0 0 @base-radius;
  flex-shrink: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.archive-info-container {
  padding: 10px 20px 10px 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.archive-title {
  font-size: 1.35rem;
  line-height: 1.7rem;
}

.archive-info {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.archive-contact {
  font-size: 1rem;
  line-height: 1.2rem;
}

.active-action {
  min-width: 210px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.show-services {
  font-size: 1rem;
  display: block;
  margin-bottom: .6rem;
  text-align: center;
}

.arch-info {
  border-right: 1px solid #c6c6c6;
}

.arch-info-img {
  min-height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.arch-info-title {
  font-weight: bold;
  margin: @base-padding 0 10px;
}

.accord-line {
  padding: 10px @base-padding;
  border-bottom: 1px solid @color-grey-outline;

  &:last-child {
    border-bottom: 0;
  }
}

.arch-info-one .card-body {
  padding: 0;
}

/*end archive info*/


.search-res-archive-list {
  list-style: none;
  padding-left: 0;

  & .badge {
    /*min-width: 50px;*/
    text-align: left;
  }
}

.payment-frame {
  width: 100%;
  height: 700px;
  border: 0;
}


.color-success {
  color: @color-green;
}

.color-error {
  color: @color-red;
}

h3.title {
  font-weight: bold;
  font-size: 20px;
}

.sub {
  margin-left: 12px;
}

.ex {
  color: #5F5F5F;
  font-size: 97%;
  margin-left: 5px;
}

.title {
}


.help-w {
  display: block;
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: @color-blue;
  border: 1px solid @color-dark-blue;
  box-sizing: border-box;
  color: #fff;
  font-size: 35px;
  line-height: 50px;
  text-align: center;
  right: 20px;
  bottom: 20px;
  box-shadow: @box-shadow;

  & .help-d {
    display: none;
    line-height: 140%;
  }

  & .help-l {
    font-size: 35px;
    color: @color-white;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    & .help-d {
      display: block;
      position: absolute;
      right: 0;
      bottom: 60px;
      background: @color-white;
      color: #000;
      text-align: left;
      width: 300px;
      padding: 15px 20px 0;
      font-size: 13px;
      border-radius: @base-radius;
      border: 1px solid @color-grey-outline;
      box-shadow: @box-shadow;
    }
  }
}

.accent {
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
}

.list-group-item.active, .list-group-item.active a {
  color: @color-white;
  background: @color-red;
  border-color: @color-red;
}

.show-notif-link {
  display: none;
  cursor: pointer;
  text-align: right;
}

.dimmed {
  opacity: .3;
}

.autocomplete-suggestions {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, .3);
  overflow: auto;

  div {
    cursor: pointer;
    padding: 3px 10px;
  }

  div:hover, .autocomplete-selected {
    background: #eeeeee;
  }

}

.description_tab table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.description_tab th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  padding: 0.3rem;
}

.description_tab td, .description_tab th {
  padding: 0.3rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.files-list {
  list-style: none;
  & li {
    margin-bottom: 10px;
    margin-top: 5px;
  }
  &__img {
    width: 100px;
    padding-right: 20px;
    padding-bottom: 10px;
    display: inline-block;
  }
}