#special {
  width: 100%;
  min-height: 2.5rem;
  z-index: 200;
  position: fixed;
  background: #ffffff;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex-wrap: wrap;
  padding-bottom: 5px;
  box-sizing: border-box;
  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 12rem;
  }
}

body.specialView #special {
  display: flex;
}

#special > div {
  display: flex;
  align-items: center;
  border-right: 1px solid #c6c6c6;
  padding: 0 10px;

  &:last-child {
    border-right: 0;
  }

  @media (max-width: 576px) {
    border-right: 0;
    padding-left: .75rem;
    padding-bottom: 10px;
  }
}

#special div, #special label, #special ul li {
  font-size: 18px;
  color: #000 !important;
}

#special ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 0;
  height: 100%;
  margin: 0;
}

#special ul li {
  padding: 5px 10px;
  cursor: pointer;
  color: #000;
}

#special #clear {
  color: #fff !important;
}

#view-images {
}

.f-small {
  font-size: 16px !important;
}

.f-mid {
  font-size: 20px !important;
}

.f-large {
  font-size: 24px !important;
}

.f-e-large {
  font-size: 32px !important;
}

.c-block {
  border: 1px solid #000;
}

#special li.c-black, .color-black body, .color-black footer {
  color: #000 !important;
  background: #fff;
}

.color-black {
  & div, & p, & a, & ul, & h1, & h2, & h3 {
    color: #000 !important;
  }
}

#special li.c-white, .color-white body {
  color: #fff !important;
  background: #000;
}

.color-white {
  & .breadcrumb, & footer, & .main-back {
    background: #000;
  }

  & .header-info .info-block {
    background: #000;
    border: 1px solid #fff;
  }

  & .list-group-item {
    background: #383838;
  }

  & .list-group-item.active, & .list-group-item.active a {
    background: #1a1a1a;
    border-color: #1a1a1a;
  }

  & div, & p, & a, & ul, & h1, & h2, & h3 {
    color: #fff !important;
  }

  & .news-container:after {
    background: none;
  }
}

#special li.c-blue, .color-blue body, .color-blue footer {
  color: #00439b !important;
  background: #9ed6fd;
}

.color-blue {
  & div, & p, & a, & ul, & h1, & h2, & h3 {
    color: #00439b !important;
  }
}

#special li.c-brown, .color-brown body, .color-brown footer {
  color: #3a1200 !important;
  background: #c0ad99;
}

.color-brown {
  & div, & p, & a, & ul, & h1, & h2, & h3 {
    color: #3a1200 !important;
  }
}

.color-black .list-group-item.active, .color-black .list-group-item.active a,
.color-blue .list-group-item.active, .color-blue .list-group-item.active a,
.color-brown .list-group-item.active, .color-brown .list-group-item.active a {
  background: #e9ecef;
  border-color: #e9ecef;
}

.color-brown .header-back,
.color-blue .header-back,
.color-white .header-back,
.color-black .header-back,
.color-brown .news-container:after,
.color-blue .news-container:after,
.color-black .news-container:after {
  background: none !important;
}

html.font-small {
  font-size: 16px;
  line-height: 20px;
}

html.font-mid {
  font-size: 20px;
  line-height: 24px;
}

html.font-large {
  font-size: 24px;
  line-height: 28px;
}

html.font-e-large {
  font-size: 32px;
  line-height: 36px;
}

#view-images label {
  padding-top: 0;
}

html.font-mid #view-images label {
  padding-top: .1rem;
}

html.font-large #view-images label {
  padding-top: .2rem;
}

html.font-e-large #view-images label {
  padding-top: .3rem;
}

html.interval-large div, html.interval-large a, html.interval-large p {
  line-height: 150%;
}

html.img-none div, html.img-none a, html.img-none p {
  background-image: none !important;
}

html.img-none img {
  visibility: hidden;
}

header img {
  visibility: visible !important;
}

body.specialView .wrap {
  padding-top: 2.5rem;
  @media (max-width: 576px) {
    padding-top: 12rem;
  }
}
