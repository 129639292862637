.header-menu > li {
  margin: 5px 0;
}
.header-menu > li > a {
  padding: 0 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.header-menu > li > a > img, .header-menu > li > a > span {
  margin-left: 10px;
}

.menu_button {
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin: 0 0 0 0;
}

.menu_button span {
  position: absolute;
  left: 0;
  top: 12px;
  z-index: 17;
}

.menu_button span,
.menu_button span:before,
.menu_button span:after {
  position: absolute;
  display: block;
  content: '';
  height: 5px;
  width: 30px;
  background: @color-blue;
  cursor: pointer;
  border-radius: 2px;
}

.menu_button span:before,
.menu_button span:after {
  height: 5px;
}

.menu_button span,
.menu_button span:before,
.menu_button span:after {
  background: @color-blue;
}

.menu.open .menu_button span,
.menu.open .menu_button span:before,
.menu.open .menu_button span:after,
.menu-other .menu_button span,
.menu-other .menu_button span:before,
.menu-other .menu_button span:after {
  background: @color-white;
}

.menu_button span:before {
  top: -11px;
}

.menu_button span:after {
  bottom: -11px;
}

/* Добавим анимацию всех свойств для блоков нашей иконки */
.menu_button span, .menu_button span:before, .menu_button span:after {
  transition: all 500ms ease-in-out;
}

/* Центральный блок делаем прозрачным */
.menu_button.active span {
  background-color: transparent;

}

/* Смещаем псевдо-элементы в центр иконки (на место основного блока) */
.menu_button.active span:before, .menu_button.active span:after {
  top: 0;
}

/* Наклоняем псевдо-элементы под углом 45 градусов, но в разные стороны */
.menu_button.active span:before {
  transform: rotate(45deg);
}

.menu_button.active span:after {
  transform: rotate(-45deg);
}



#menuItems {
  display: block;
  position: relative;
  width: 100%;
  right: 0;
  text-align: left;
  top: 0;
  height: auto;
  transition: all ease-in-out .3s;
  z-index: 16;
  padding: 12px 0 0;
  border-radius: 0 0 4px 4px;

  & > ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    color: @color-white;
    padding: 0;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    & > li {
      position: relative;
      margin-right: 20px;

      &:hover {
        & > a:after {
        content: "" attr(data-title) "";
        display: block;
        position: absolute;
        z-index: +2;
        top: 0;
        }
        & > ul {
          display: block;
        }
      }

      & ul {
        position: absolute;
        display: none;
        background: @color-dark-blue;
        z-index: +1;
        border-radius: 15px;
        list-style: none;
        margin-left: -60px;
        width: calc(100% + 120px);

        & > li a {

          display: block;
          padding: 7px 20px;
          line-height: 21px;
          font-size: 18px;
          @media screen and (max-width: 1000px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      & > ul {
        top: -10px;
        padding: 50px 0 10px;
      }
    }

    & > li a {
      font-size: 20px;
      line-height: 23px;
      color: @color-link;
      @media screen and (max-width: 1000px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

#menuItems li a {
  color: @color-link;
}

#navbarHeader {
  .active a {
    border-bottom: 2px solid   @color-primary-text;
  }
  a {
    font-size: 1.4rem;
    text-transform: uppercase;
    display: inline-block;
  }
  @media (max-width: 990px) {
    font-size: 1.2rem;
    background: @color-primary;
    padding-left: 10px !important;
  }
  @media (max-width: 575px) {
    padding-left: 16px !important;
  }
}

.header-menu-line {
  /*height: 50px;*/
  transition: all ease-in-out .3s;
  i {
    color: @color-secondary;
    font-size: 1.2rem;
  }
  /*@media (max-width: 800px) {
    height: 0;
    overflow: hidden;
    &.active {
      height: auto;
      & #menuItems > ul {
        position: relative;
        margin-top: 0;
        & > li > ul {
          top: -20px;
          position: relative;
          margin-left: -15px;
          padding: 30px 15px 15px 25px;
          width: calc(100% + 30px);
          border-radius: 0;
        }
      }
    }
  }*/

}



