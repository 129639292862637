#article-index {

  h1 {
    display: inline-block;
  }

  .search-button {
    cursor: pointer;
  }

  list-view:first-child div {
    display: none;
  }
}

.article-item {
  margin-bottom: 15px;

  .article-title,
  .article-title a {
    color: inherit;
    margin: 0;
  }

  .article-meta,
  .article-meta a {
    color: #999;
    font-size: .85em;
  }


  .article-meta a {
    text-decoration: underline;
  }

  .article-thumb {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .article-text {
    margin-top: 7px;
  }
}


#newsSlide {
  height: 500px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: all .5s ease-in-out;
  padding-right: 10px;
}

.main-news-nav {
  display: flex;
  justify-content: center;

  & a {
    border: 1px solid @color-red;
    border-radius: 50%;
    margin: 0 20px;

    & #nav-left, & #nav-right {
      fill: @color-red-light;

      &:hover {
        fill: @color-red;
      }
    }
  }
}

.to-show-news {
  cursor: pointer;
}



.article-item {
  margin-bottom: 15px;

  &-block {
    height: 600px;
  }

  & .article-content {
    overflow: hidden;
    height: 530px;
    position: relative;

    &::after {
      display: block;
      content: ' ';
      position: absolute;
      width: 100%;
      height: 50px;
      bottom: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 80%);
    }
  }
}

.article-item .article-title,
.article-item .article-title a {
  color: inherit;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.article-item .article-meta,
.article-item .article-meta a {
  color: #999;
  font-size: .85em;
}

.article-item .article-meta {
  overflow: hidden;
  height: 25px;
  position: relative;

  &::after {
    display: block;
    content: ' ';
    position: absolute;
    width: 50px;
    height: 25px;
    top: 0;
    right: 0;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 80%);
  }

}

.article-meta-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  .article-date {
    font-weight: bold;
  }
}

.article-item .article-meta a {
  text-decoration: underline;
}

.article-item .article-thumb {
  margin-right: 10px;
  margin-bottom: 10px;
}

.article-item .article-text {
  margin-top: 7px;
  font-size: 18px;
  line-height: 21px;
  text-align: justify;
}

.article-image {
  width: 320px;
  margin-right: 15px;
  float: left;
}
